.footer {
    background-color: #ec3237;
    border-top: 5px solid #fdc93b;
    width: 100%;
}

.footer_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.footer_top .social_media {
    color: #fdc93b;
    font-size: 23px;
    gap: 20px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.footer_top .social_media  p {
    padding-top: 0px;
    margin-top: 0px;
    cursor: pointer;
}

.footer_top img{
    width: 200px;
}

.footer_bottom {
    display: flex;
    justify-content: center;
    align-items: start;
}

.footer_bottom div{
    width: 80%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid white;
    font-weight: 500;
}
.footer_bottom p{
    display: flex;
    justify-content: center;
}

.footer_sec {
    display: flex;
    justify-content: center;
    align-items: start;
}

.footer_sec .footer_sec_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 5 colunas de largura igual */
    gap: 20px; /* Espaçamento entre as colunas */
    padding: 20px 0; /* Espaçamento interno */
}

.footer_sec .footer_sec_grid div{
    width: 200px;
}

.footer_sec .footer_sec_grid h3 {
    font-weight: 600;
    color: #fdc93b;
}

.footer_sec .footer_sec_grid p {
    cursor: pointer;
    color: white;
    font-weight: 500;
}

.footer_sec .footer_sec_grid p:hover {
    text-decoration: underline;
    color: #fdc93b;
}
@media (max-width:900px) {
    .footer_sec .footer_sec_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 5 colunas de largura igual */
        gap: 20px; /* Espaçamento entre as colunas */
        padding: 20px 0; /* Espaçamento interno */
    }
    .footer_bottom div{
        width: 90%;
    }
}

@media (max-width:700px) {
    .footer_sec .footer_sec_grid {
        width: 90%;
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* 5 colunas de largura igual */
        gap: 20px; /* Espaçamento entre as colunas */
        padding: 20px 0; /* Espaçamento interno */
    }
    .footer_sec .footer_sec_grid div{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
    }
    .footer_bottom div{
        width: 95%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 1px solid white;
        font-weight: 500;
        text-align: justify;
    }

    .footer_bottom p{
        width: 90%;
    }
}