.all {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.HeroSec {
    background-color: #efefef;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Alinha o conteúdo verticalmente */
    padding: 0 20px;
    /* Opcional: adiciona um espaço interno */
}

.HeroRight {
    width: 50%;
    height: 100%;
    background-color: #efefef;
}

.HeroRight h2 {
    color: rgb(21, 31, 63);
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 38px;
    font-weight: 600;
    line-height: 108%;
}

.HeroRight p {
    color: rgb(68, 65, 85);
    text-align: left;
    letter-spacing: 0;
    margin-bottom: 0;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 125%;
}

.HeroRight button {
    background-color: #ec3237;
    color: white;
    text-align: center;
    white-space: nowrap;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 6px;
    padding: 11px 19px;
    font-family: Manrope, sans-serif;
    font-size: 18px;
    font-weight: 500;
    transition: background-color .2s;
    display: block;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.HeroRight button:hover {
    background-color: #aa272b;
}

.HeroLeft {
    width: 50%;
    height: 100%;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HeroLeft img {
    width: 200px;
    height: auto;

}

@media (max-width:800px) {
    .HeroSec {
        background-color: rgb(255, 255, 255);
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        background-color: blue;
        padding: 0px;
    }

    .HeroRight {
        width: 100%;
        height: auto;
        background-color: #efefef;
        display: flex;
        justify-content: center;
    }
    
    .HeroRight_content {
        width: 90%;
    }

    .HeroRight button {
        width: 100%;
    }

    .HeroLeft {
        width: 100%;
        height: auto;
        background-color: #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
    }
}