.oftenAsk {
    background-color: #f0f0f0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 100px;
}

.oftenAsk_top h2 {
    color: #22303e;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 110%;
}

.oftenAsk_bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    padding-bottom: 150px;
}

.ListOftenAsk {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-bottom: 1.5px solid #ec3237;
}

.ItemOftenAsk {
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    border-top: 1.5px solid #ec3237;
    padding-top: 22px;
    padding-bottom: 22px;
}

.ItemOftenAsk .ask {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #22303e;
    transition: 1s;
}

.ItemOftenAsk .ask.active {
    color: #ec3237;
}

.ItemOftenAsk .ask .top,
.ItemOftenAsk .ask.active .top {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 110%;
}

.ItemOftenAsk .ask .bottom,
.ItemOftenAsk .ask.active .bottom {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 110%;
}

.ItemOftenAsk .answer {
    width: 434px;
    color: #22303e;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 110%;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (max-width:800px) {
    .ListOftenAsk {
        width: 90%;
    }
}