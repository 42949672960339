.header {
    width: 100%;
    height: 140px;
    background-color: rgb(240, 240, 240);
    font-family: "Quicksand", sans-serif;
}

.header .container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: end;
    background-color: #ffffffe5;
    position: fixed;
    top: 0;
}

.navbar {
    width: 95%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.w_nav {
    width: 30%;
    height: 100%;
}

.w_nav img {
    height: 100%;
}

.w_nav_menu {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemsMenu {
    display: flex;
    align-items: center;
    gap: 20px;
}

.itemsMenu_div {
    gap: 10px;
}

.itemsMenu_div_link {
    color: #22303eb3;
    border-bottom: 4px #fff;
    padding-top: 36px;
    padding-bottom: 36px;
    font-size: 18px;
    gap: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.7s;
}

.itemsMenu_div_link:hover {
    color: #dd3737;
}

.arrowIcon {
    color: #192531d0;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    font-size: 17px;
}

.prevMenu {
    height: 100%;
    width: 100px;
    display: flex;
    justify-content: start;
    align-items: center;
    display: none;
}

.hamburgerMenu {
    height: 70px;
    width: 70px;
    display: none;
    font-size: 26px;
    cursor: pointer;
    color: #22303e;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

.itemsMenuHamburger {
    display: none;
}

.button {
    width: 170px;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.button button {
    border: 1px none var(--blue-violet);
    background-color: #ec3237;
    width: 95.56px;
    height: 44px;
    color: white;
    text-align: center;
    white-space: nowrap;
    border-radius: 6px;
    padding: 12px 18px;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-weight: 500;
    transition: background-color .2s;
    display: block;
    cursor: pointer;
    transition: 1.5s;
}

.button button:hover {
    background-color: #af2227;
}

/* Acess popup */
.AcessAll {
    background-color: #110e14b3;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContentAll {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: white;
    border-radius: 24px;
    flex-direction: column;
    flex: 1;
    width: 60%;
    max-width: 60%;
    height: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 24px;
    display: flex;
    overflow: auto;
}

.ContentAll h2 {
    color: rgb(16, 18, 36);
    font-size: 30px;
    font-weight: 500;
}

.ListAcess {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.AccessItem {
    background-color: #fae6e6;
    background-image: linear-gradient(#f9f2f2, #f9f2f2);
    border-radius: 24px;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: stretch;
    width: 30%;
    height: auto;
    padding: 24px 24px 32px;
    transition: none;
    display: flex;
    gap: 10px;
}

.AccessItem:hover {
    background-color: #f9f2f2;
    background-image: linear-gradient(#f8cece, #f9f2f2);
}

.AccessItem div {
    color: #dd3737;
}

.AccessItem .icon {
    font-size: 32px;
    color: #dd3737;
}

.AccessItem .name {
    color: rgb(16, 18, 36);
}

.AccessItem h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}

.AccessItem a {
    text-decoration: none;
}

.AccessItem .button {
    border: 1px solid #ec3237;
    background-color: white;
    width: 95.56px;
    height: 44px;
    color: #ec3237;
    text-align: center;
    white-space: nowrap;
    border-radius: 6px;
    padding: 12px 18px;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-weight: 500;
    transition: background-color .2s;
    display: block;
    cursor: pointer;
    transition: 0.7s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AccessItem .button:hover {
    border: 1px solid rgb(255, 162, 162);
    background-color: rgb(255, 162, 162);
    color: #ec3237;
    font-family: "Quicksand", sans-serif;

}

.mobileMenu {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: start;
}

.mobileMenu_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 10px;
}

/* Media query para telas com largura de 900px ou menos */
@media (max-width: 997px) {
    .ContentAll {
        max-width: 60%;
    }

    .w_nav_menu {
        justify-content: right;
    }

    .itemsMenu {
        display: none;
    }

    .prevMenu {
        display: flex;
    }

    .prevMenu p {
        margin-block-start: 20px;
    }

    .hamburgerMenu {
        display: block;
        /* Mostra o menu de hambúrguer */
    }

    .button {
        width: 90px;
    }

    .ListAcess {
        overflow-x: auto;
        max-width: 630px;
        max-height: 230px;
    }

    .AccessItem {
        max-width: 197.16px;
        max-height: 180px;
        padding: 14px 14px 22px;
        gap: 0px;
    }

    .ContentAll h2 {
        font-size: 25px;
    }
}

@media screen and (max-width: 700px) {
    .ContentAll {
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: white;
        border-radius: 24px;
        flex-direction: column;
        flex: 1;
        max-width: 75%;
        max-height: 90%;
        margin-top: 24px;
        margin-bottom: 24px;
        padding: 24px;
        display: flex;
        overflow: auto;
    }

    .ContentAll h2 {
        font-size: 22px;
        font-weight: 600;
    }

    .ListAcess {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        overflow-x: none;
        max-width: 100%;
        max-height: 600px;
    }

    .AccessItem {
        width: 100%;
        max-width: 90%;
        max-height: 100px;
        padding: 14px 14px 22px;
        gap: 0px;
    }

    .AccessItem div {
        max-width: 100%;
        width: 100%;
    }

    .AccessItem .icon {
        max-height: 25px;
    }

    .AccessItem .name {
        max-height: 50px;
        font-size: 15px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .AccessItem .name h3 {
        margin: 0px;
        padding: 0px;
    }

    .AccessItem .button {
        max-width: 90%;
        width: 100%;
        height: 10px;
        max-height: 70px;
    }
    .mobileMenu_grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 479px) {
    .ContentAll {
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        background-color: white;
        border-radius: 24px;
        flex-direction: column;
        flex: 1;
        max-width: 80%;
        margin-top: 24px;
        margin-bottom: 24px;
        padding: 24px;
        display: flex;
        overflow: auto;
    }

    .button button {
        white-space: normal;
        justify-content: center;
        width: 79.56px;
        height: 44px;
        display: flex;
    }

    .AccessItem .button {
        max-width: 80%;
        width: 100%;
    }
}